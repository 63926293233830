<template>
  <div id="wrapper">
    <div v-if="gpsDisabled" id="disabled-gps-message">
      {{ $t("gps.disabled1") }}<br/>
      {{ $t("gps.disabled2") }}<br/><br/>

      <button class="button is-success" @click="startGps">
        {{ $t("gps.disabledLetsGo") }}
      </button>
    </div>
    <div id="distance" v-if="gpsDisabled === false && position && distanceToTarget < 10000">
      {{ distanceToTarget }}m
    </div>
    <div id="waiting-for-gps-signal" v-if="gpsDisabled === undefined && !position">
      <img src="/img/gps-signal.png"/>
      <progress class="progress is-small is-default" max="100">15%</progress>
    </div>
    <div id="close-to-destination" v-if="closeToDestination" class="notification is-info">
      <p v-if="precise">
        {{ $t("scene.map.closeToArrival") }}<br/>
        <span>{{ $t("scene.map.estimatedDistance") }} {{ distanceToTarget }}m</span>
      </p>
      <p v-if="!precise">
        {{ $t("scene.map.hereYouAre") }}
      </p>
      <button class="button is-success" @click="validate" v-if="!isValidated">
        <span v-if="precise">{{ $t("scene.map.hereIAm") }}</span>
        <span v-if="!precise">{{ $t("common.continue") }}</span>
      </button>
      <progress class="progress is-small is-default" max="100" v-if="isValidated">15%</progress>
    </div>
    <div id="map" v-if="gpsDisabled === false">
      <mgl-map
          ref="map"
          :zoom="zoom"
          :bearing="bearing"
          :dragRotate="false"
          :pitchWithRotate="false"
          :touchZoomRotate="true"
          :fadeDuration="0"
          :pitch="20"
          @map:zoomstart="isZooming = true"
          @map:zoomend="isZooming = false"
          @map:touchstart="isTouching = true"
          @map:touchend="isTouching = false"
          @map:dragstart="isDragging = true"
          @map:dragend="isDragging = false"
          @map:load="onLoadMap"
          language="fr"
      >

        <mgl-navigation-control/>

      </mgl-map>
    </div>
  </div>
</template>

<script>
import 'maplibre-gl/dist/maplibre-gl.css'
import GpsSceneWithTargetMixin from '@/mixins/GpsSceneWithTarget'
import { MglMap, MglDefaults, MglNavigationControl } from 'vue-maplibre-gl'

MglDefaults.style = {
  version: 8,
  sources: {
    osm: {
      type: 'raster',
      tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
      tileSize: 512,
      maxzoom: 19
    }
  },
  layers: [
    {
      id: 'osm',
      type: 'raster',
      source: 'osm'
    }
  ]
}

export default {
  name: 'SceneMapNew',
  props: ['attributes', 'number'],
  components: {
    MglMap, MglNavigationControl
  },
  mixins: [GpsSceneWithTargetMixin],
  data () {
    return {
      zoom: 17,
      bearing: 0,
      centerCoordinatesValue: [0, 0],
      closeToDestination: false,
      isValidated: false,
      isZooming: false,
      isTouching: false,
      isDragging: false,
      map: null
    }
  },
  computed: {
    currentPositionGeoJson () {
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: this.centerCoordinates
            }
          }
        ]
      }
    },
    targetGeoJson () {
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [this.attributes.target.long, this.attributes.target.lat]
            }
          }
        ]
      }
    },
    pathGeoJson () {
      let coordinates = []
      if (this.targetOnly) {
        if (this.position) {
          coordinates = [
            [this.position.coords.longitude, this.position.coords.latitude],
            [this.attributes.target.long, this.attributes.target.lat]
          ]
        }
      }
      if (!this.targetOnly) {
        for (const point of this.attributes.points) {
          coordinates.push([point[1], point[0]])
        }
        coordinates.push([this.attributes.target.long, this.attributes.target.lat])
      }
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates
        }
      }
    },
    targetOnly () {
      return this.attributes.points.length === 0
    },
    precise () {
      return this.attributes.precise
    },
    centerCoordinates () {
      if (!this.attributes) {
        return []
      }
      // If we have the current position, center on it
      // Otherwise, center on target destination
      if (this.position) {
        return [this.position.coords.longitude, this.position.coords.latitude]
      }
      return [this.attributes.target.long, this.attributes.target.lat]
    },
    // Travel path coordinates
    lineCoordinates () {
      if (!this.attributes) {
        return []
      }
      const res = []
      for (const point of this.attributes.points) {
        res.push([point[1], point[0]])
      }
      res.push([this.attributes.target.long, this.attributes.target.lat])
      return res
    },
    // When there is no travel path, but only a target, draw a direct line
    // between current position and target
    directTargetLineCoordinates () {
      if (!this.position) {
        return []
      }
      return [
        [this.position.coords.longitude, this.position.coords.latitude],
        [this.attributes.target.long, this.attributes.target.lat]
      ]
    },
    // Target, used by mixin to calculate distance
    target () {
      return this.attributes.target
    }
  },
  watch: {
    closeToDestination (value) {
      if (value) {
        // Play sound when close to destination
        const track = this.$store.state.audio.tracks[2]

        track.volume = 1
        track.src = '/sound/arrivee.mp3'
      }
    },
    currentOrientationDegrees (value) {
      if (this.isZooming || this.isTouching || this.isDragging) {
        return
      }
      this.bearing = -value
    },
    centerCoordinates (value) {
      if (this.isZooming || this.isTouching || this.isDragging) {
        return
      }
      this.centerCoordinatesValue = value
    },
    position (value) {
      if (!this.map) {
        return
      }
      const source = this.map.getSource('point')
      source.setData(this.currentPositionGeoJson)
      this.map.panTo(this.centerCoordinates)
    }
  },
  methods: {
    async onLoadMap (el) {
      this.map = el.map
      this.map.setCenter(this.centerCoordinates)
      const image = await el.map.loadImage('/img/gps-current-position.webp')
      el.map.addImage('person', image.data)
      const image2 = await el.map.loadImage('/img/gps-target.webp')
      el.map.addImage('target', image2.data)
      for (const [index, icon] of this.attributes.icons.entries()) {
        // your code goes here
        const tmpImg = await el.map.loadImage(icon.file)
        el.map.addImage('icon' + index.toString(), tmpImg.data)
      }

      // add lines
      el.map.addSource('path', {
        type: 'geojson',
        data: this.pathGeoJson
      })
      el.map.addLayer({
        id: 'path',
        type: 'line',
        source: 'path',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'red',
          'line-width': 3
        }
      })

      // Target
      if (this.attributes.showTargetIcon) {
        el.map.addSource('target', {
          type: 'geojson',
          data: this.targetGeoJson
        })
        el.map.addLayer({
          id: 'target',
          type: 'symbol',
          source: 'target',
          layout: {
            'icon-image': 'target',
            'icon-size': 0.15,
            'icon-allow-overlap': true
          }
        })
      }

      // add icons
      for (const [index, icon] of this.attributes.icons.entries()) {
        console.log(index, icon)
        el.map.addSource('icon' + index.toString(), {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [icon.long, icon.lat]
                }
              }
            ]
          }
        })
        el.map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'icon' + index.toString(),
          layout: {
            'icon-image': 'icon' + index.toString(),
            'icon-size': 0.2,
            'icon-allow-overlap': true
          }
        })
      }

      // Add current position
      el.map.addSource('point', {
        type: 'geojson',
        data: this.currentPositionGeoJson
      })
      el.map.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'point',
        layout: {
          'icon-image': 'person',
          'icon-size': 0.15,
          'icon-allow-overlap': true
        }
      })
    },
    // Raised by mixin when user is close to target
    onDistanceUpdate (distance) {
      if (distance > 50) {
        this.closeToDestination = false
        return
      }
      if (distance < this.attributes.immediateProximity) {
        this.closeToDestination = true
      } else if (distance < this.attributes.approximateProximity) {
        const comp = this
        setTimeout(function () {
          if (!comp.closeToDestination && comp.distanceToTarget < comp.attributes.approximateProximity) {
            // Still behind 20m within 15s. Let's validate - maybe an imprecise GPS
            comp.closeToDestination = true
          }
        }, this.attributes.approximateProximityDuration * 1000)
      }
    },
    validate () {
      if (this.isValidated) {
        return
      }
      this.$store.dispatch('validateItem', {
        number: this.number
      })
      this.isValidated = true
    }
  }
}
</script>

<style scoped lang="sass">
  #wrapper
    height: 100%

  #map
    width: 100%
    height: 100%
    background-color: white

  #waiting-for-gps-signal
    margin-top: 40px
    img
      display: block
      width: 60px
      margin: auto
    .progress
      margin: auto
      margin-top: 5px
      width: 200px

  #close-to-destination, #waiting-for-gps-signal
    position: absolute
    z-index: 2000
    width: 100%
    text-align: center

  #close-to-destination
    top: 12%
    text-align: center
    padding: 0px 50px
    box-sizing: border-box
    color: black
    background-color: rgba(255, 255, 255, 0.9)
    p
      font-weight: bold
      span
        font-weight: normal
        display: block
        padding-top: 5px
    button
      margin: 10px 0px
    progress
      margin-top: 24px
      margin-bottom: 24px
      display: inline-flex

  #disabled-gps-message
    padding-top: 100px

  #distance
    position: absolute
    bottom: 10px
    left: 10px
    background-color: white
    border: solid 1px black
    z-index: 500
    font-size: 14px
    padding: 2px 5px

</style>
<style lang="sass">
.maplibregl-map
  height: 100% !important
.maplibregl-ctrl-attrib
  display: none
</style>
